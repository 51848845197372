import Head from "next/head";
import React from "react";
import { lightTheme } from "../../theme/shared";
import { appears } from "../../utils/animations";
import { StyledImg } from "../StyledImg";
import styles from "../../styles/Common.module.css";

export const OfficialSiteLayout: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => (
  <div className={styles.container}>
    <Head>
      <meta property="og:description" content="" key="description" />
      <meta property="og:type" content="website" key="type" />
      <meta property="og:locale" content="ja_JP" key="locale" />
      <meta property="og:site_name" content="Kuadomi Web." key="site_name" />
      <link rel="icon" href="/favicon.ico" key="icon" />
    </Head>

    <main className={styles.main}>
      <div>
        <StyledImg
          className="docs__gradient-violet"
          alt="gradient violet background"
          css={{
            display: "none",
            top: 0,
            opacity: 0,
            position: "fixed",
            animation: `${appears} 200ms 100ms ease forwards`,
            "@lg": {
              top: "-50%",
              right: "-50%",
            },
            "@mdMax": {
              top: "-35%",
              right: "-45%",
            },
            [`.${lightTheme} &`]: {
              display: "block",
            },
          }}
        />
        {children}
      </div>
    </main>
  </div>
);
