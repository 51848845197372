import Head from "next/head";
import {
  Grid,
  Spacer,
} from "@nextui-org/react";
import { NextPage } from "next";
import { OfficialSiteLayout } from "../components/layouts/OfficialSiteLayout";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Image, { ImageProps } from "next/image";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useState } from "react";

const renderImage = (node: React.ReactNode, imageProps: Omit<ImageProps, 'src'>, key?: React.Key) => (
  <Image
    {...imageProps}
    alt=""
    src={(node as any).props.src}
    key={key}
  />
)

const KuadomiPage: NextPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const numOfImages = 16;
  return (
    <div>
      <Head>
        <meta property="og:url" content="https://mimimimi.megumi.sh" />
        <meta property="og:title" content="Kuadomi Web." />
        <meta property="og:image" content={"https://mimimimi.megumi.sh/works/" + (numOfImages - 1) + ".png"} />
        <meta property="og:description" content="" />
        <title>Kuadomi Web.</title>
      </Head>

      <Spacer />

      <Grid.Container justify="center">
        <Grid>
          <Carousel
            showArrows
            dynamicHeight
            emulateTouch
            autoFocus
            autoPlay
            infiniteLoop
            showThumbs={false}
            renderItem={(node) => renderImage(node, {
              objectFit: "cover",
              width: 250,
              height: 250,
            })}
            onClickItem={(index, _) => { setIsOpen(true); setImageIndex(index) }}

          >
            {[...Array(numOfImages)].map((_, i) => <Image alt="" key={i} src={"/thumbnails/" + (numOfImages - (i + 1)) + ".png"} />)}
          </Carousel>
        </Grid>
      </Grid.Container>
      {
        isOpen && (
          <Lightbox
            mainSrc={"/works/" + (numOfImages - (imageIndex + 1)) + ".png"}
            nextSrc={"/works/" + (numOfImages - ((imageIndex + 1) % numOfImages) + 1) + ".png"}
            prevSrc={"/works/" + (numOfImages - ((imageIndex - 1) % numOfImages) + 1) + ".png"}
            onCloseRequest={() => setIsOpen(false)}
            onMoveNextRequest={() => { setImageIndex((imageIndex + 1) % numOfImages) }}
            onMovePrevRequest={() => { setImageIndex((imageIndex + numOfImages - 1) % numOfImages) }}
          />
        )
      }
    </div >
  )
};

KuadomiPage.getLayout = (page) => <OfficialSiteLayout>{page}</OfficialSiteLayout>;
export default KuadomiPage;
